import { yupPrefixedKeyStringSchema } from '@nucleus/ncu-key-prefix';
import * as yup from 'yup';

export interface CodeSnippet {
  id: string;
  name: string;
  language: 'html' | 'css' | 'javascript';
  location?: string;
  content: string;
  enabled: boolean;
}

export const CodeSnippetWebKeys = ['id', 'language', 'location', 'content', 'enabled'] as const;
export type CodeSnippetWebKeys = (typeof CodeSnippetWebKeys)[number];

export type CodeSnippetWeb = Pick<CodeSnippet, CodeSnippetWebKeys>;

export const codeSnippetSchema: yup.SchemaOf<CodeSnippet> = yup.object().shape({
  id: yupPrefixedKeyStringSchema('codesnippet').required(),
  name: yup.string().trim().required(),
  language: yup.string().trim().oneOf(['css', 'html', 'javascript']).required(),
  location: yup.string(),
  content: yup.string().trim().default('').strict(true),
  enabled: yup.boolean().default(false).required(),
});
