import { SermonWeb } from '@nucleus/types/web/sermons/sermon';
import { ErrorBoundary, SectionProvider, useNavigateSSR } from '@nucleus/web-theme';
import { MarkupRegistryContext } from '@nucleus/web-theme/src/components/markup/MarkupRegistryContext';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate, useResolvedPath } from 'react-router-dom';
import { SermonRoutes } from './SermonRoutes';
import { SermonHubController } from './controllers/SermonHubController';

type SermonHubProps = SermonWeb;

const isAxiosRedirectError = (
  error: Error
): error is AxiosError<{ location: string }> & {
  response: NonNullable<AxiosError<{ location: string }>['response']>;
} => isAxiosError(error) && error.response?.data?.location !== undefined && [301, 302].includes(error.response.status);

export const SermonHub = (props: SermonHubProps): JSX.Element => {
  const { ssrNavigate } = useNavigateSSR();
  const navigate = useNavigate();
  const base = useResolvedPath('');

  const handleError = useCallback((error: Error, info: React.ErrorInfo, reset: () => void): void => {
    if (!isAxiosRedirectError(error)) {
      return;
    }

    const location = error.response.data.location;
    const status = error.response.status;

    const isSSR = typeof window === 'undefined';
    const isExternalUrl = /^https?:\/\//.test(location);

    switch (true) {
      case isSSR && isExternalUrl:
        ssrNavigate(location, status);
        break;
      case isSSR && !isExternalUrl:
        ssrNavigate(`${base.pathname}/${location}`, status);
        break;
      case !isSSR && isExternalUrl:
        window.location.href = location;
        break;
      case !isSSR && !isExternalUrl:
      default:
        navigate(location, { replace: true });
        break;
    }

    reset();
  }, []);

  const { pathname } = useLocation();

  return (
    <SermonHubController engineId={props.id} basePath={base.pathname}>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            key={pathname}
            onError={handleError}
            onReset={reset}
            fallbackRender={({ error }) => <SectionErrorFallback error={error} />}
          >
            <SermonRoutes />
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </SermonHubController>
  );
};

const SectionErrorFallback = ({ error }: { error: Error }) => {
  const { getByType, sectionRenderErrorData } = useContext(MarkupRegistryContext);

  const MarkupComponent = getByType(sectionRenderErrorData?.type ?? '');

  if (sectionRenderErrorData === undefined || MarkupComponent === undefined) {
    return (
      <div>
        There was an error!
        <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
      </div>
    );
  }

  return (
    <SectionProvider section={sectionRenderErrorData}>
      <MarkupComponent />
    </SectionProvider>
  );
};
