import { createCompositeString } from '@nucleus/lib-shape';

const TableSeparator = '-';

export const getTableName = (tableName: string, serviceName: string, stage?: string): string => {
  if (process.env['NODE_ENV'] === 'test') {
    return tableName;
  }

  if (stage === undefined) {
    return tableName;
  }

  return createCompositeString([serviceName, stage, tableName], TableSeparator);
};
