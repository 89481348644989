import { getEnvVar } from '@nucleus/lib-environment';
import { Stage } from '@nucleus/ncu-constants';
import { getTableName } from './dynamodb';

export const TABLE_NAME_ACTIVITY = getTableName('activity', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_AUDITLOG = getTableName('auditlog', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_CHURCH = getTableName('church', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_CHURCHPERSON = getTableName('churchperson', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_EMAIL = getTableName('email', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_FILE = getTableName('file', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_INVITATION = getTableName('invitation', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_LOGINCOGNITO = getTableName('logincognito', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_NOTIFICATION = getTableName('notification', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_ORGANIZATION = getTableName('organization', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_PERSON = getTableName('person', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_PROFILE = getTableName('profile', 'NucleusPlatformResources', Stage);
export const TABLE_NAME_ROLE = getTableName('role', 'NucleusPlatformResources', Stage);

export const NUCLEUS_DASHBOARD_APP_CLIENT_ID = getEnvVar('NUCLEUS_DASHBOARD_APP_CLIENT_ID');
export const REBELGIVE_APP_CLIENT_ID = getEnvVar('REBELGIVE_APP_CLIENT_ID');

export const HELPSCOUT_WEBHOOK_SECRET_KEY = getEnvVar('HELPSCOUT_WEBHOOK_SECRET_KEY', '');
export const HELPSCOUT_BEACON_ADMIN_SECRET_KEY = getEnvVar('HELPSCOUT_BEACON_ADMIN_SECRET_KEY', '');
export const NUCLEUS_HELPSCOUT_BEACON_ADMIN_SECRET_KEY = getEnvVar('NUCLEUS_HELPSCOUT_BEACON_ADMIN_SECRET_KEY', '');

export const COGNITO_USER_STATUS_UNCONFIRMED = 'UNCONFIRMED';
export const COGNITO_USER_STATUS_CONFIRMED = 'CONFIRMED';
export const COGNITO_USER_STATUS_ARCHIVED = 'ARCHIVED';
export const COGNITO_USER_STATUS_COMPROMISED = 'COMPROMISED';
export const COGNITO_USER_STATUS_UNKNOWN = 'UNKNOWN';
export const COGNITO_USER_STATUS_RESET_REQUIRED = 'RESET_REQUIRED';
export const COGNITO_USER_STATUS_FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD';
export const COGNITO_USER_STATUSES = [
  COGNITO_USER_STATUS_UNCONFIRMED,
  COGNITO_USER_STATUS_CONFIRMED,
  COGNITO_USER_STATUS_ARCHIVED,
  COGNITO_USER_STATUS_COMPROMISED,
  COGNITO_USER_STATUS_UNKNOWN,
  COGNITO_USER_STATUS_RESET_REQUIRED,
  COGNITO_USER_STATUS_FORCE_CHANGE_PASSWORD,
];

export const ASSET_BUCKET_NAME = getEnvVar('ASSET_BUCKET_NAME');

export const REBELGIVE_CONTROL_CENTER_URL = getEnvVar('CONTROL_CENTER_WEBSITE_URL');
export const NUCLEUS_DASHBOARD_URL = getEnvVar('NUCLEUS_DASHBOARD_URL');
export const EMAIL_ASSET_ROOT_URL = getEnvVar('EMAIL_ASSET_ROOT_URL');
export const NUCLEUS_FROM_EMAIL_ADDRESS = getEnvVar('NUCLEUS_EMAIL_FROM_ADDRESS', '');
export const NUCLEUS_REPLY_TO_EMAIL_ADDRESS = getEnvVar('NUCLEUS_EMAIL_REPLY_TO_ADDRESS', NUCLEUS_FROM_EMAIL_ADDRESS);
export const NUCLEUS_CHURCH_FROM_EMAIL_DOMAIN = getEnvVar('NUCLEUS_EMAIL_FROM_CHURCH_DOMAIN');
export const REBELGIVE_FROM_EMAIL_ADDRESS = getEnvVar('EMAIL_FROM_ADDRESS', '');

export const NUCLEUS_ONE_BASE_URL = getEnvVar('NUCLEUS_ONE_BASE_URL');

export const TERM_GIVE_GIVE = 'give';
export const TERM_GIVE_DONATE = 'donate';
export const TERM_GIVES = [TERM_GIVE_GIVE, TERM_GIVE_DONATE];

export const TERM_FUND_FUND = 'fund';
export const TERM_FUND_DESIGNATION = 'designation';
export const TERM_FUNDS = [TERM_FUND_FUND, TERM_FUND_DESIGNATION];

export const TERM_LOCATION_LOCATION = 'location';
export const TERM_LOCATION_CAMPUS = 'campus';
export const TERM_LOCATIONS = [TERM_LOCATION_LOCATION, TERM_LOCATION_CAMPUS];

export const GIVING_FLOW_COLOR_MODE_LIGHT = 'GIVING_FLOW_COLOR_MODE_LIGHT';
export const GIVING_FLOW_COLOR_MODE_DARK = 'GIVING_FLOW_COLOR_MODE_DARK';
export const GIVING_FLOW_COLOR_MODES = [GIVING_FLOW_COLOR_MODE_LIGHT, GIVING_FLOW_COLOR_MODE_DARK];

// You cannot get more than 100 items at a time.
export const DYNAMODB_BATCH_GET_MAX_ITEMS = 100;

//DynamoDB transaction action items must have length equal to or less than 25 (https://docs.aws.amazon.com/amazondynamodb/latest/APIReference/API_TransactWriteItems.html)
export const DYNAMODB_TRANSACTION_MAX_ITEMS = 25;

export const MAX_ITEMS_LISTED = Math.min(parseInt(getEnvVar('MAX_ITEMS_LISTED', '100')), DYNAMODB_BATCH_GET_MAX_ITEMS);

export const CHURCHPERSON_TYPE_LINKING = 'linking';
export const CHURCHPERSON_TYPE_PROFILE = 'profile';
