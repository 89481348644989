import { CalendarEvent } from '@nucleus/calendar';
import { serializeRichtextToHtml, serializeRichtextToText } from '@nucleus/deprecated-rich-text';
import { objectKeys } from '@nucleus/lib-shape';
import { parseIsoToDate } from '@nucleus/src-platform/data';
import { DateArray, EventAttributes, createEvent } from 'ics';
import { pick } from 'lodash';
import { useCallback, useMemo } from 'react';

interface transformHandler {
  (event: CalendarEvent): Partial<EventAttributes>;
}

const extractProperties: transformHandler = (event) => pick(event, ['title']);

const transformUid: transformHandler = (event) => ({ uid: event.eventId });

const transformRecurrence: transformHandler = (event) => {
  if (event.recurring === undefined) {
    return {};
  }

  const rule = objectKeys(event.recurring).reduce((rule, key) => {
    let value = event.recurring?.[key];
    if (value === undefined) {
      return rule;
    }

    if (Array.isArray(value)) {
      value = value.join(',');
    }

    return rule + `${key}=${value};`;
  }, '');

  return {
    recurrenceRule: rule.toUpperCase(),
  };
};

const transformDates: transformHandler = (event) => {
  const dateKeys = ['start', 'end'] as const;

  const [start, end] = dateKeys.map((key): DateArray | undefined => {
    const date = event[key];
    if (date === undefined) {
      return undefined;
    }

    const dateObj = parseIsoToDate(date);

    const dateParts: DateArray = [dateObj.getUTCFullYear(), dateObj.getUTCMonth() + 1, dateObj.getUTCDate()];

    if (event.allDay === true) {
      return dateParts;
    }

    return [...dateParts, dateObj.getUTCHours(), dateObj.getUTCMinutes()];
  });

  return {
    start: start,
    startInputType: 'utc',
    end: end,
    endInputType: 'utc',
    duration: end === undefined ? event.duration : undefined,
  };
};

const transformDescription: transformHandler = (event) => {
  const description = event.description;

  if (description === undefined) {
    return {};
  }
  try {
    return {
      description: serializeRichtextToText(description),
      htmlContent: serializeRichtextToHtml(description),
    };
  } catch (error) {
    console.log('error serializing description', error, description);
    return {};
  }
};

const transformLocation: transformHandler = (event) => {
  const location = event.location;
  if (location === undefined) {
    return {};
  }

  if (location.locationType === 'richtext') {
    return {
      location: serializeRichtextToText(location.nodes),
    };
  }

  return {
    location: undefined, // TODO: parse postal address location when location supports it
  };
};

const transformers: Array<transformHandler> = [
  extractProperties,
  transformDates,
  transformDescription,
  transformLocation,
  transformRecurrence,
  transformUid,
];

export const useIcsEventDownload = (event: CalendarEvent): [() => void, string | undefined] => {
  const ics = useMemo(() => {
    const attributes = transformers.reduce(
      (acc: Partial<EventAttributes>, transformer) => ({ ...acc, ...transformer(event) }),
      {}
    ) as EventAttributes;

    const { error, value } = createEvent(attributes);
    if (error !== undefined && error !== null) {
      console.error(error);
    }
    return value;
  }, [event]);

  const handleDownload = useCallback(() => {
    if (ics === undefined) {
      alert('Error creating calendar event');
      return;
    }

    const blob = new Blob([ics], { type: 'text/calendar' });
    // Create a download URL for the Blob
    const downloadURL = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${event.title}.ics`;

    // Append the link to the DOM and click it programmatically to trigger the download
    document.body.appendChild(link);
    link.click();
  }, [ics]);

  return [handleDownload, ics];
};
