import { PageWeb } from '@nucleus/types/web';
import { PageSeoTags, SectionProvider, SectionsProvider, SiteContext } from '@nucleus/web-theme';
import { RichTextParameterContext, useRichTextParameterContext } from '@nucleus/web-theme-elements';
import { ContentLoader } from '@nucleus/web-theme/src/components/content/ContentLoader';
import { MarkupRegistryContext } from '@nucleus/web-theme/src/components/markup/MarkupRegistryContext';
import { SectionErrorBoundary } from '@nucleus/web-theme/src/components/sections/SectionErrorBoundary';
import React, { useContext } from 'react';
import { useSermonHub } from '../../controllers/SermonHubController';
import { Loading } from '../Loading';

interface RendererProps {
  isLoading: boolean;
  error?: any;
  page?: PageWeb;
}

export const Renderer = ({ isLoading, ...props }: RendererProps): JSX.Element => {
  if (isLoading) {
    return <Loading />;
  }

  if (props.error) {
    return <ErrorFallback error={props.error} />;
  }

  const { sections, content } = props.page ?? { sections: {}, content: { main: [] } };

  return (
    <RichTextParameterContext parameters={props.page?.parameters}>
      <RenderSeoTags page={props.page} />
      <SectionErrorBoundary fallbackRender={() => <ErrorFallback error={new Error('Unknown Error')} />}>
        <SectionsProvider sections={sections}>
          {content.main.map((item) => (
            <ContentLoader key={item.id} data={item} />
          ))}
        </SectionsProvider>
      </SectionErrorBoundary>
    </RichTextParameterContext>
  );
};

const RenderSeoTags = ({ page }: { page?: PageWeb }) => {
  const { basePath } = useSermonHub();
  const { getSite } = useContext(SiteContext);
  const { parameters } = useRichTextParameterContext();

  if (page === undefined) {
    return null;
  }

  return <PageSeoTags site={getSite()} page={page} parameters={parameters} pathPrefix={basePath} />;
};

const ErrorFallback = ({ error }: { error: Error }) => {
  const { getByType, sectionRenderErrorData } = useContext(MarkupRegistryContext);

  const MarkupComponent = getByType(sectionRenderErrorData?.type ?? '');

  if (sectionRenderErrorData === undefined || MarkupComponent === undefined) {
    return (
      <div>
        There was an error!
        <pre style={{ whiteSpace: 'normal' }}>{error.message}</pre>
      </div>
    );
  }

  return (
    <SectionProvider section={sectionRenderErrorData}>
      <MarkupComponent />
    </SectionProvider>
  );
};
