import {
  getSSMClient,
  newParameterStoreService,
  newParameterValueService,
  newSSMParameterRepository,
} from '@nucleus/lib-parameter-store';
import { NameGeneratorFactory } from './stage';

const repository = newSSMParameterRepository(getSSMClient());

const bondParameterStoreService = newParameterStoreService(repository, {
  nameGenerator: NameGeneratorFactory('NucleusBonds'),
});

/**
 * SSM Parameter /${STAGE}/NucleusBonds/ToMetadataResolverArn
 */
export const ToMetadataResolverArn = newParameterValueService(bondParameterStoreService, 'ToMetadataResolverArn');
