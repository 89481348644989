import Joi from '@hapi/joi';
import * as libShape from '@nucleus/lib-shape';
import { validateOrThrow } from './shape';

const PREFIX_SCHEMA = Joi.string().lowercase().min(1).alphanum().required();

export const keySchema = Joi.string().regex(/[a-z0-9]+_[0-9a-f]{0,32}/);

/**
 * @deprecated use import { yupKeyStringSchema } from '@nucleus/lib-shape';
 */
export const yupKeyString = libShape.yupKeyStringSchema;

/**
 * @deprecated use import { yupPrefixedKeyStringSchema } from '@nucleus/lib-shape';
 */
export const yupPrefixedKeyString = libShape.yupPrefixedKeyStringSchema;

export function joiPrefixedKey(prefixes) {
  validateOrThrow(prefixes, Joi.alternatives().try(PREFIX_SCHEMA, Joi.array().items(PREFIX_SCHEMA)));

  let prefix = prefixes;
  if (Array.isArray(prefixes) === true) {
    prefix = `(${prefixes.join('|')})`;
  }
  const reggie = new RegExp(`^${prefix}_[0-9a-f]{1,32}$`);
  return Joi.string().regex(reggie);
}

/**
 * @deprecated use import { createDeterministicPrefixedKey } from '@nucleus/lib-shape';
 */
export const deterministicPrefixedKey = libShape.createDeterministicPrefixedKey;

/**
 * @deprecated use import { isPrefixedKey } from '@nucleus/lib-shape';
 */
export const isPrefixedKey = libShape.isPrefixedKey;

/**
 * @deprecated use import { getKeyPrefix } from '@nucleus/lib-shape';
 */
export const prefixOfKey = libShape.getKeyPrefix;

/**
 * @deprecated use import { getKeyValue } from '@nucleus/lib-shape';
 */
export const valueOfKey = libShape.getKeyValue;

/**
 * @deprecated use import { createPrefixedKeyFactory } from '@nucleus/lib-shape';
 */
export const prefixedKeyGenerator = libShape.createPrefixedKeyFactory;

/**
 * @deprecated use import { isPrefixedKeyFactory } from '@nucleus/lib-shape';
 */
export const isPrefixedKeyGenerator = libShape.isPrefixedKeyFactory;

/**
 * @deprecated use import { createCompositeStringFromObjectFactory } from '@nucleus/lib-shape';
 */
export const compositeKeyGenerator = libShape.createCompositeStringFromObjectFactory;

/**
 * @deprecated use import { createCompositeString } from '@nucleus/lib-shape';
 */
export const compositeStringGenerator = libShape.createCompositeString;

/**
 * @deprecated use import { getCompositeStringValues } from '@nucleus/lib-shape';
 */
export const getCompositeKeyValues = libShape.getCompositeStringValues;
