import { PageWeb } from '@nucleus/types/web';
import { useUser } from '@nucleus/web-theme';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { querySermonPage } from '../query/querySermonPage';

type QueryResult = Extract<Awaited<ReturnType<typeof querySermonPage>>, { page: PageWeb }>;

export const useSermonPage = (engineId: string, basePath: string, slug: string): UseQueryResult<QueryResult> => {
  const { currentUser, jwtToken } = useUser();

  return useQuery({
    queryKey: ['sermonHub', 'page', slug, currentUser?.id],
    queryFn: () => querySermonPage(engineId, basePath, slug, jwtToken),
    suspense: true,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
