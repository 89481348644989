import { QueryConfiguration } from '@nucleus/lib-dynamodb';
import { Stage } from './stage';
import { getTableName } from './utilities/tables';

export const MasterTableName = getTableName('master', 'NucleusPlatformResources', Stage);

export const MasterTableKeys = {
  partkey: 'partkey',
  sortkey: 'sortkey',
  gsi1name: 'gsi1GlobalIndex',
  gsi1partkey: 'gsi1_partkey',
  gsi1sortkey: 'gsi1_sortkey',
  gsi2name: 'gsi2GlobalIndex',
  gsi2partkey: 'gsi2_partkey',
  gsi2sortkey: 'gsi2_sortkey',
  gsi3name: 'gsi3GlobalIndex',
  gsi3partkey: 'gsi3_partkey',
  gsi3sortkey: 'gsi3_sortkey',
  ttl: 'delete_after',
} as const;

export type MasterTablePrimaryKeys = {
  [MasterTableKeys.partkey]: string;
  [MasterTableKeys.sortkey]: string;
};

export type MasterTableGsi1Keys = {
  [MasterTableKeys.gsi1partkey]: string;
  [MasterTableKeys.gsi1sortkey]: string;
};

export type MasterTableGsi2Keys = {
  [MasterTableKeys.gsi2partkey]: string;
  [MasterTableKeys.gsi2sortkey]: string;
};

export type MasterTableGsi3Keys = {
  [MasterTableKeys.gsi3partkey]: string;
  [MasterTableKeys.gsi3sortkey]: string;
};

export type MasterTableTtlKeys = {
  [MasterTableKeys.ttl]: number;
};

export const MasterTablePrimaryQueryConfig: QueryConfiguration = {
  partKey: MasterTableKeys.partkey,
  sortKey: MasterTableKeys.sortkey,
};

export const MasterTableGsi1QueryConfig: QueryConfiguration = {
  name: MasterTableKeys.gsi1name,
  partKey: MasterTableKeys.gsi1partkey,
  sortKey: MasterTableKeys.gsi1sortkey,
};

export const MasterTableGsi2QueryConfig: QueryConfiguration = {
  name: MasterTableKeys.gsi2name,
  partKey: MasterTableKeys.gsi2partkey,
  sortKey: MasterTableKeys.gsi2sortkey,
};

export const MasterTableGsi3QueryConfig: QueryConfiguration = {
  name: MasterTableKeys.gsi3name,
  partKey: MasterTableKeys.gsi3partkey,
  sortKey: MasterTableKeys.gsi3sortkey,
};
