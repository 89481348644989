import {
  ContentRenderer,
  createMarkupRegistry,
  LookConfigContext,
  LookConfigProvider,
  WebTheme,
} from '@nucleus/web-theme';
import { nucleusClass } from '@nucleus/web-theme-elements';
import React from 'react';
import { LifeV1ThemeConfig } from '../config/ThemeConfig';
import { LoadingSpinner } from './components/LoadingSpinner';
import { PageGlobalHtml } from './components/PageGlobalHtml';
import { PageGlobalScripts } from './components/PageGlobalScripts';
import { PageGlobalStyle } from './components/PageGlobalStyle';
import { PageLoading } from './components/PageLoading';
import { PageMetadata } from './components/PageMetadata';
import { StyledThemeProvider } from './components/StyledThemeProvider';
import { ThemeGlobalStyle } from './components/ThemeGlobalStyle';
import { RichTextProvider } from './contexts/RichTextContext';
import { sectionRenderErrorFallback } from './fallbacks';
import { useScrollToTop } from './hooks/useScrollToTop';
import { CalendarSection } from './sections/Calendar/CalendarSection';
import { CardSection } from './sections/Card/CardSection';
import { EmbedSection } from './sections/Embed/EmbedSection';
import { FooterSection } from './sections/Footer/FooterSection';
import { GallerySection } from './sections/Gallery/GallerySection';
import { HeaderSection } from './sections/Header/HeaderSection';
import { InfoSection } from './sections/Info/InfoSection';
import { ListSection } from './sections/List/ListSection';
import { SermonSection } from './sections/Sermon/SermonSection';
import { SermonMediaSection } from './sections/SermonMedia/SermonMediaSection';
import { SermonSearchRequestSection } from './sections/SermonSearchRequest/SermonSearchRequestSection';
import { SermonSearchResultsSection } from './sections/SermonSearchResultsSection.tsx/SermonSearchResultsSection';
import { fallbackFontPack } from './theme/fonts';

const MarkupRegistry = createMarkupRegistry(
  {
    calendar: CalendarSection,
    card: CardSection,
    embed: EmbedSection,
    footer: FooterSection,
    gallery: GallerySection,
    header: HeaderSection,
    info: InfoSection,
    leader: CardSection,
    list: ListSection,
    loading: LoadingSpinner,
    sermon: SermonSection,
    sermonMedia: SermonMediaSection,
    sermonSearchRequest: SermonSearchRequestSection,
    sermonSearchResults: SermonSearchResultsSection,
    sermonActions: () => null,
  },
  {
    sectionRenderErrorData: sectionRenderErrorFallback,
  }
);

export const App = (): JSX.Element => {
  useScrollToTop();
  const color = LifeV1ThemeConfig().colorPalettes[0];

  const lookConfigContext: LookConfigContext = {
    colors: color,
    fonts: fallbackFontPack,
  };

  return (
    <LookConfigProvider value={lookConfigContext}>
      <WebTheme>
        <ThemeGlobalStyle />
        <StyledThemeProvider>
          <MarkupRegistry.Provider>
            <RichTextProvider>
              <div className={nucleusClass('page')}>
                <PageLoading>
                  <PageGlobalStyle />
                  <PageGlobalScripts />
                  <PageGlobalHtml />
                  <PageMetadata />
                  <ContentRenderer contentArea="main" />
                </PageLoading>
              </div>
            </RichTextProvider>
          </MarkupRegistry.Provider>
        </StyledThemeProvider>
      </WebTheme>
    </LookConfigProvider>
  );
};

export default App;
